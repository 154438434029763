//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

import List from '@/components/arbitrator/Calls/List.vue';
import TradeInfo from '@/components/arbitrator/Calls/TradeInfo.vue';
import Pagination from '@/components/Pagination.vue';

export default {

  components: {
    List,
    TradeInfo,
    Pagination,
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('arbitratorCalls', ['page', 'pages', 'data']),

    tradeId() {
      return Number(this.$route.query.tradeId ?? 0);
    },

    currentTrade() {
      return this.data?.find((e) => e.id === this.tradeId);
    },
  },

  watch: {
    page(newPage) {
      if (newPage) {
        this.loadDeals({ page: newPage, limit: 10 });
      }
    },
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadDeals(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', [
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
      'setGeneralProgress',
      'setGeneralLoader',
    ]),
    ...mapActions('arbitratorCalls', {
      loadData: 'loadData',
      setPageAction: 'setPage',
      joinAction: 'join',
      assignAction: 'assign',
    }),

    async loadDeals() {
      await this.loadData();
      if (this.data) this.select(this.data[0].id);
    },

    setPage(page) {
      this.setPageAction(page);
    },

    select(tradeId) {
      if (tradeId !== Number(this.$route.query.tradeId)) this.$router.replace({ query: { tradeId } });
    },

    async join(id) {
      this.confirmAction({
        title: 'Are you sure you want to join in this deal as arbitrator?',
        callback: async () => {
          this.setGeneralLoader(true);
          try {
            await this.joinAction(id);
            await this.assignAction(id);
            this.loadDeals();
            this.setSuccessNotification('You have successfuly joined this deal');
            this.$router.push({ name: 'arbitrator-my-arbitration' });
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralLoader(false);
        },
      });
    },
  },
};

