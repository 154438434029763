//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheDetails from '@/components/arbitrator/Trade/Details.vue';
import User from '@/components/arbitrator/Trade/User.vue';

export default {
  components: {
    TheDetails,
    User,
  },

  props: {
    trade: { type: Object, default: () => ({}) },
    loader: { type: Boolean, default: false },
  },

  computed: {
    cryptoBuyer() {
      return this.$props.trade?.cryptoBuyer;
    },

    cryptoSeller() {
      return this.$props.trade?.cryptoSeller;
    },

    isBuyerInitial() {
      return this.$props.trade?.cryptoBuyer?.username === this.$props.trade?.initialSideUsername;
    },

    isSellerInitial() {
      return this.$props.trade?.cryptoSeller?.username === this.$props.trade?.initialSideUsername;
    },
  },

};

