//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import widget from '@/components/arbitrator/Common/Widget.vue';

export default {
  components: {
    widget,
  },

  props: {
    items: { type: Array, required: true },
    current: { type: Number, required: true },
    select: { type: Function, required: true },
    join: { type: Function, required: true },
    loader: { type: Boolean, required: false },
    button: { type: Boolean, default: true },
  },

  methods: {
    getSellerUsername(item) {
      return item.cryptoSeller?.username;
    },

    getBuyerUsername(item) {
      return item.cryptoBuyer?.username;
    },

    isSellerCalledArbitrator(item) {
      return item.cryptoSeller?.username === item.userCalledArbitratorUsername;
    },

    isBuyerCalledArbitrator(item) {
      return item.cryptoBuyer?.username === item.userCalledArbitratorUsername;
    },

    isActive(item) {
      return item.id === Number(this.$route.query.tradeId);
    },
  },
};

